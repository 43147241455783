function TikTok() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="35"
			height="36"
			fill="none"
			aria-labelledby="tiktok-logo-title"
			role="img"
		>
			<title id="tiktok-logo-title">TikTok Logo</title>
			<path
				fill="currentColor"
				d="M17.5 0C7.837 0 0 7.89 0 17.616c0 9.728 7.837 17.616 17.5 17.616S35 27.344 35 17.616C35 7.89 27.163 0 17.5 0Z"
			/>
			<path
				fill="#fff"
				d="M26.28 13.479v2.385a8.232 8.232 0 0 1-5.06-1.73l.016 7.341a6.17 6.17 0 0 1-1.832 4.377 6.222 6.222 0 0 1-4.426 1.823 6.23 6.23 0 0 1-4.425-1.823 6.176 6.176 0 0 1-1.82-4.795 6.183 6.183 0 0 1 1.543-3.717 6.24 6.24 0 0 1 4.702-2.117c.318 0 .633.024.941.07v3.335a2.947 2.947 0 0 0-.93-.15 2.966 2.966 0 0 0-2.96 3.017 2.977 2.977 0 0 0 2.794 2.922c.385.021.754-.033 1.096-.146a2.971 2.971 0 0 0 2.03-2.821l.003-4.917V7.557h3.264a5.085 5.085 0 0 0 2.011 4.014 5.014 5.014 0 0 0 3.043 1.024l.01-.001v.885Z"
			/>
		</svg>
	);
}

export default TikTok;
