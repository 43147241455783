import { Button } from '@components/Button';
import '@components/Footer.scss';
import DownCaret from '@components/icons/DownCaret';
import { orderedCurrencies } from '@data/currencies';
import { footerMenuItems, type FooterMenuItems } from '@data/footerMenuItems';
import { useTranslation } from '@hooks/useTranslation';
import CollabLogoTwo from '@icons/CollabLogoTwo';
import FacebookLogo from '@icons/FacebookLogo';
import Instagram from '@icons/Instagram';
import Pinterest from '@icons/Pinterest';
import TikTok from '@icons/TikTok';
import Twitter from '@icons/Twitter';
import { useStore } from '@nanostores/preact';
import CTInspectionsLogo from '@public/images/cti-logo.svg';
import CTWarrantyLogo from '@public/images/ctw-logo.png';
import MasterCardLogo from '@public/images/mastercard.png';
import PaypalLogo from '@public/images/paypal.png';
import CooperationPartner1Logo from '@public/images/professional-member.png';
import SepaLogo from '@public/images/sepa.png';
import VisaCardLogo from '@public/images/visa.png';
import {
	country as countryStore,
	type SupportedCountries,
	type SupportedLocales,
} from '@store/user';
import { deleteCookie, getLanguage, setCookie } from '@utils/index';
import type { ComponentProps, RenderableProps } from 'preact';
import type { ChangeEvent } from 'preact/compat';
import { useState } from 'preact/hooks';
import { AccordionFooterMenu } from './AccordionFooterMenu';

type FooterProps = {
	hrefLangs?: Record<string, string>;
	currency?: string;
	locale: SupportedLocales;
	isFooterReduced?: boolean;
};
export const Footer = ({
	hrefLangs,
	currency,
	locale,
	isFooterReduced,
}: FooterProps) => {
	const { t, tIgnoredByParse } = useTranslation();
	const country = useStore(countryStore);
	const [showLanguageSelect, setShowLanguageSelect] = useState(false);
	const [showCurrencySelect, setShowCurrencySelect] = useState(false);
	const [hreflangValue, setHreflangValue] = useState(
		locale.toLocaleLowerCase()
	);

	const onSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
		const target = e.target as HTMLSelectElement;
		const selectedValue = target.value;
		const url = hrefLangs ? hrefLangs[selectedValue] : null;
		if (!url) {
			return;
		}
		setHreflangValue(selectedValue);
		const newUrl = new URL(url);
		const redirectUrl = new URL(window.location.href);
		redirectUrl.pathname = newUrl.pathname;
		deleteCookie('currency');
		window.location.href = redirectUrl.href;
	};
	const onCurrencyChange = (e: ChangeEvent<HTMLSelectElement>) => {
		const target = e.target as HTMLSelectElement;
		const selectedValue = target.value;
		const redirectUrl = new URL(window.location.href);
		redirectUrl.searchParams.set('currency', selectedValue);
		setCookie('currency', selectedValue, 365);
		window.location.href = redirectUrl.href;
	};

	return (
		<footer
			className={`bg-brand-gray-footer ${
				isFooterReduced ? 'w-full' : null
			}`}
		>
			<div className="mx-auto w-full  max-w-6xl px-4 ">
				{isFooterReduced ? (
					<FooterReduced country={country} />
				) : (
					<div className="footer_elements max-w-lg items-start pb-16 pt-8 lg:max-w-full">
						<div className="lang_select mt-7 w-full xs:text-start lg:mt-[unset]">
							<label
								htmlFor="hreflangs"
								className="text-center text-lg font-semibold xs:text-start"
							>
								{t('footer.chooseLanguage')}
							</label>
							<div className="relative">
								<Select
									name="hreflangs"
									id="hreflangs"
									aria-label={t('footer.chooseLanguage')}
									onClick={() => {
										setShowLanguageSelect(
											!showLanguageSelect
										);
									}}
									value={hreflangValue}
									onChange={onSelectChange}
								>
									{hrefLangs
										? Object.entries(hrefLangs).map(
												([hrefLocale]) => (
													<option value={hrefLocale}>
														{getLanguage(
															hrefLocale
														)}
													</option>
												)
										  )
										: null}
								</Select>
								<span className="absolute bottom-4 right-2">
									<span
										className={`inline-block transition-all duration-300 ${
											showLanguageSelect
												? 'rotate-180'
												: 'rotate-0'
										}`}
									>
										<DownCaret />
									</span>
								</span>
							</div>
						</div>
						<div className="currency_wrapper mt-7 flex w-full flex-col lg:mt-[unset]">
							<label
								htmlFor="currency"
								className="text-lg font-semibold"
							>
								{t('footer.switchCurrency')}
							</label>
							<div className="relative">
								<Select
									name="currency"
									id="currency"
									aria-label={t('footer.switchCurrency')}
									onClick={() => {
										setShowCurrencySelect(
											!showCurrencySelect
										);
									}}
									onChange={onCurrencyChange}
								>
									{orderedCurrencies.map((currencyOption) => (
										<option
											value={currencyOption}
											name={currencyOption}
											selected={
												currencyOption === currency
											}
										>
											{currencyOption}
										</option>
									))}
								</Select>
								<span className="absolute bottom-4 right-2">
									<span
										className={`inline-block transition-all duration-300 ${
											showCurrencySelect
												? 'rotate-180'
												: 'rotate-0'
										}`}
									>
										<DownCaret />
									</span>
								</span>
							</div>
						</div>
						<div className="newsletter_wrap mt-2 w-full border-t border-brand-gray-dark pt-2 lg:mt-[unset] lg:w-11/12 lg:justify-self-end lg:border-none lg:pt-[unset]">
							<div className="flex w-full flex-grow flex-col">
								<div className="text-lg font-semibold">
									Classic Trader Newsletter
								</div>
								<div className="mt-2.5">
									<NewsletterForm />
								</div>
							</div>
						</div>

						<div className="nav_column nav_1 hidden w-full lg:mt-10 lg:block">
							<div className=" mb-5 text-lg font-semibold lg:block">
								Classic Trader
							</div>
							<ul className="lg:h-full">
								{footerMenuItems.classicTrader.map((item) => (
									<FooterMenuItem
										country={country}
										item={item}
										key={`${country}-${item.i18nKey}`}
									/>
								))}
							</ul>
						</div>
						<div className="nav_column nav_2 hidden w-full lg:mt-10 lg:block">
							<div className="mb-5 text-lg font-semibold">
								{t('footer.title.support')}
							</div>
							<ul>
								{footerMenuItems.support.map((item) => (
									<FooterMenuItem
										country={country}
										item={item}
										key={`${country}-${item.i18nKey}`}
									/>
								))}
							</ul>
						</div>
						<div className="nav_column nav_3 hidden w-full lg:mt-10 lg:block">
							<div className="mb-5 text-lg font-semibold">
								{t('footer.title.service')}
							</div>
							<ul>
								{footerMenuItems.service.map((item) => (
									<FooterMenuItem
										country={country}
										item={item}
										key={`${country}-${item.i18nKey}`}
									/>
								))}
							</ul>
						</div>

						<AccordionFooterMenu
							insertClassName="nav_column nav_1 w-full"
							country={country}
						/>

						<div className="socials_wrapper mt-7 w-full border-b border-brand-gray-dark pb-5 lg:mt-10 lg:border-none lg:pb-[unset] lg:pl-[8.333%]">
							<div className="mb-5 mt-2.5 text-lg font-semibold lg:mt-[unset]">
								{t('footer.title.socials')}
							</div>
							<div className="social_links flex flex-wrap justify-center gap-x-4 text-brand-blue-default xs:flex-nowrap lg:justify-start lg:gap-x-[30px]">
								<a
									href="https://www.facebook.com/pages/Classic-Trader/251697228297615?fref=ts"
									aria-label="Facebook"
								>
									<FacebookLogo />
								</a>
								<a
									href="https://twitter.com/Classic_Trader"
									aria-label="Twitter"
								>
									<Twitter />
								</a>
								<a
									href="https://www.pinterest.com/ClassicsForSale/"
									aria-label="Pinterest"
								>
									<Pinterest />
								</a>
								<a
									href="https://www.instagram.com/classic_trader"
									aria-label="Instagram"
								>
									<Instagram />
								</a>
								<a
									href="https://www.tiktok.com/@classictrader"
									aria-label="TikTok"
								>
									<TikTok />
								</a>
							</div>
						</div>

						<div className="coop_wrapper mt-7 w-full border-t border-brand-gray-dark pt-7 lg:border-none lg:pl-[8.333%] lg:pt-[unset] ">
							<div className="text-lg font-semibold ">
								{t('footer.title.inCooperationWith')}
							</div>
							<div className="collab_logos mt-4 flex w-full flex-grow justify-start gap-4 lg:gap-8">
								<div className="inline-flex max-w-[68px]">
									<img
										src={CooperationPartner1Logo.src}
										alt=""
										loading="lazy"
									/>
								</div>
								<div className="inline-flex">
									<CollabLogoTwo />
								</div>
							</div>
						</div>
						<div className="ct_services_wrapper mt-7 w-full border-t border-brand-gray-dark  pt-7 lg:border-none lg:pl-[8.333%] lg:pt-[unset] ">
							<div className="text-lg font-semibold ">
								CT Services
							</div>
							<div className="mt-4 flex flex-row flex-wrap gap-4 lg:gap-8">
								<a
									href="https://www.ct-inspections.com"
									target="_blank"
									rel="noopener noreferrer"
								>
									<img
										src={CTInspectionsLogo.src}
										alt="CT Warranty"
										className="max-h-[36px] w-full max-w-[165px]"
										loading="lazy"
									/>
								</a>
								<a
									href="https://www.ct-warranty.com"
									target="_blank"
									rel="noopener noreferrer"
								>
									<img
										src={CTWarrantyLogo.src}
										alt="CT Warranty"
										className="max-h-[36px] w-full max-w-[165px]"
										loading="lazy"
									/>
								</a>
							</div>
						</div>

						<div className="separator col-span-12 mt-7 h-[1px] w-full bg-brand-gray-dark lg:mt-5" />

						<div className="payments_wrapper w-full gap-4 pt-7 xs:gap-0 lg:pt-7">
							<div className="text-xl font-semibold">
								{t('footer.title.paySecurely')}
							</div>
							<div className="payment_images flex flex-wrap items-center justify-items-start gap-4 gap-x-4 xs:gap-2.5 md:mt-2.5 lg:justify-start lg:gap-x-[50px]">
								<div className="payments_logo max-w-[80px]">
									<img
										className="h-auto w-full "
										src={PaypalLogo.src}
										alt="Paypal Logo"
										loading="lazy"
									/>
								</div>
								<div className="middle_payments_grouped flex items-center gap-x-3">
									<div className="payments_logo inline-flex md:max-w-[40px]">
										<img
											className="h-auto w-full"
											src={MasterCardLogo.src}
											alt="Mastercard Logo"
											loading="lazy"
										/>
									</div>
									<div className="payments_logo inline-flex md:max-w-[45px]">
										<img
											className="h-auto w-full"
											src={VisaCardLogo.src}
											alt="Visa Logo"
											loading="lazy"
										/>
									</div>
								</div>
								<div className="payments_logo max-w-[60px]">
									<img
										className="h-auto w-full"
										src={SepaLogo.src}
										alt="SEPA Logo"
										loading="lazy"
									/>
								</div>
							</div>
						</div>
						<div className="copyright_wrapper w-full pt-7 text-sm sm:pl-0 lg:pl-[8.333%] lg:pt-7">
							<div className="flex flex-wrap justify-center gap-x-2.5 border-t border-brand-gray-dark pb-2.5 pt-2.5 lg:justify-start lg:border-t-0">
								{footerMenuItems.legal.map((item) => (
									<a
										href={item.href[country]}
										className="text-brand-blue-default hover:underline"
									>
										{tIgnoredByParse(item.i18nKey)}
									</a>
								))}
							</div>
							<span className="block text-center text-brand-gray-border lg:text-left">
								© Copyright {new Date().getFullYear()}{' '}
								Classic&nbsp;Trader&nbsp;GmbH
							</span>
						</div>
					</div>
				)}
			</div>
		</footer>
	);
};
function FooterReduced({ country }: { country: SupportedCountries }) {
	const { tIgnoredByParse } = useTranslation();
	return (
		<div className="mx-auto max-w-lg pb-9 pt-6 text-xs [padding-bottom:calc(env(safe-area-inset-bottom)+2.5rem)] sm:text-sm">
			<div className="flex flex-wrap items-center justify-between gap-2">
				{footerMenuItems.legal.map((item) => (
					<a
						href={item.href[country]}
						className="p-2 font-medium text-brand-blue-default hover:underline"
					>
						{tIgnoredByParse(item.i18nKey)}
					</a>
				))}
			</div>
			<span className="block pt-3 text-center text-brand-gray-border">
				© Copyright {new Date().getFullYear()}{' '}
				Classic&nbsp;Trader&nbsp;GmbH
			</span>
		</div>
	);
}
function NewsletterForm() {
	const { t } = useTranslation();
	const country = useStore(countryStore);
	return (
		<form
			action={`/${country}/newsletter`}
			method="GET"
			className="flex flex-wrap justify-center gap-4 xs:flex-nowrap xs:justify-start xs:gap-0 lg:gap-x-4"
		>
			<input
				name="email"
				type="email"
				placeholder={t('footer.newsletter.placeholder')}
				className="w-full border border-brand-gray-border p-4"
			/>
			<Button
				kind="secondary"
				size="large"
				iconName="carret"
				label={t('footer.newsletter.cta')}
			/>
		</form>
	);
}
function Select({
	children,
	...props
}: RenderableProps<ComponentProps<'select'>>) {
	return (
		<select
			{...props}
			className="relative z-0 col-span-2 mt-2.5 min-h-[58px] w-full flex-grow cursor-pointer appearance-none border border-brand-gray-border bg-white px-5 py-3 leading-8"
		>
			{children}
		</select>
	);
}

type FooterMenuItemProps = {
	item: FooterMenuItems[keyof FooterMenuItems][number];
	country: SupportedCountries;
};
function FooterMenuItem({ item, country }: FooterMenuItemProps) {
	const { tIgnoredByParse } = useTranslation();
	const href = item.href[country];
	if (!href) {
		return null;
	}
	return (
		<li className="pb-3.5">
			<a href={href} className="text-brand-gray-default hover:underline">
				{tIgnoredByParse(item.i18nKey)}
			</a>
		</li>
	);
}
