function Instagram() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="35"
			height="36"
			fill="none"
			aria-labelledby="instagram-logo-title"
			role="img"
		>
			<title id="instagram-logo-title">Instagram Logo</title>
			<path
				fill="currentColor"
				fill-rule="evenodd"
				d="M17.5 0C7.837 0 0 7.888 0 17.616c0 9.73 7.837 17.616 17.5 17.616 9.666 0 17.5-7.887 17.5-17.616C35 7.888 27.166 0 17.5 0Z"
				clip-rule="evenodd"
			/>
			<path
				fill="#fff"
				fill-rule="evenodd"
				d="M28.026 22.915c0 2.95-2.382 5.347-5.309 5.347H12.284c-2.929 0-5.312-2.397-5.312-5.347v-10.5c0-2.947 2.383-5.347 5.311-5.347h10.434c2.927 0 5.31 2.4 5.31 5.347v10.5Z"
				clip-rule="evenodd"
			/>
			<path
				fill="currentColor"
				fill-rule="evenodd"
				d="M22.715 9.375H12.284a3.032 3.032 0 0 0-3.02 3.039v10.5c0 1.678 1.353 3.042 3.02 3.042h10.43c1.666 0 3.021-1.364 3.021-3.042v-10.5c0-1.677-1.355-3.04-3.02-3.04Zm-5.218 13.75c-2.989 0-5.422-2.45-5.422-5.46 0-3.008 2.434-5.459 5.422-5.459 2.992 0 5.423 2.45 5.423 5.46 0 3.008-2.434 5.458-5.423 5.458Zm5.598-9.79a1.288 1.288 0 0 1-1.283-1.291c0-.71.575-1.29 1.283-1.29.706 0 1.284.58 1.284 1.29a1.29 1.29 0 0 1-1.284 1.292Z"
				clip-rule="evenodd"
			/>
			<path
				fill="currentColor"
				fill-rule="evenodd"
				d="M17.496 14.514c-1.725 0-3.131 1.413-3.131 3.15 0 1.74 1.406 3.154 3.131 3.154 1.728 0 3.131-1.414 3.131-3.153 0-1.737-1.403-3.151-3.13-3.151Z"
				clip-rule="evenodd"
			/>
		</svg>
	);
}

export default Instagram;
