import { useRef, useState } from 'preact/hooks';
import DownCaret from '@icons/DownCaret';
import { footerMenuItems, type FooterMenuItems } from '@data/footerMenuItems';
import { useTranslation } from '@hooks/useTranslation';
import type { SupportedCountries } from '@store/user';

type Props = {
	insertClassName: string;
	country: SupportedCountries;
};
export function AccordionFooterMenu({ country, insertClassName }: Props) {
	const { t, tIgnoredByParse } = useTranslation();
	const propClassName = insertClassName;
	const footerMenu = footerMenuItems;
	const formatItem = formatItemWithLocale(country);
	return (
		<ul className={`accordion ${propClassName}`}>
			<AccordionFooterMenuItem
				title="Classic Trader"
				items={footerMenu.classicTrader.map(formatItem)}
			/>
			<AccordionFooterMenuItem
				title={t('footer.title.support', 'Support')}
				items={footerMenu.support.map(formatItem)}
			/>
			<AccordionFooterMenuItem
				title={t('footer.title.service', 'Service')}
				items={footerMenu.service.map(formatItem)}
			/>
		</ul>
	);

	function formatItemWithLocale(country: SupportedCountries) {
		return (item: FooterMenuItems[keyof FooterMenuItems][number]) => ({
			href: item.href[country],
			linkText: tIgnoredByParse(item.i18nKey),
		});
	}
}

type AccordionFooterMenuItemProps = {
	title: string;
	items: { linkText: string; href: string | null }[];
};
function AccordionFooterMenuItem({
	title,
	items,
}: AccordionFooterMenuItemProps) {
	const [clicked, setClicked] = useState(false);
	const contentEl = useRef<HTMLUListElement>(null);

	const handleToggle = () => {
		setClicked((prev) => !prev);
	};

	return (
		<li
			className={` accordion_item block lg:mt-10  lg:hidden ${
				clicked ? 'is_active' : ''
			}`}
		>
			<button
				className="nav_column nav_1 mt-2.5 flex items-center justify-between lg:mt-10 lg:hidden"
				onClick={handleToggle}
				type="button"
			>
				<div
					className={`text-brand-blue-default lg:block ${
						clicked ? 'font-semibold' : 'font-thin'
					} `}
				>
					{title}
				</div>
				<span
					className={`transform text-brand-blue-default ${
						clicked ? 'rotate-180' : ''
					} origin-center duration-500 ease-in-out`}
				>
					<DownCaret />
				</span>
			</button>

			<ul
				ref={contentEl}
				className={`ml-2.5 mt-2 h-0 lg:h-full ${
					clicked ? '' : 'overflow-hidden '
				} `}
				style={
					clicked
						? { height: contentEl?.current?.scrollHeight }
						: { height: '0px' }
				}
			>
				{items.map(
					(item) =>
						item.href && (
							<li
								className="py-[5px] text-left font-thin text-brand-blue-default"
								key={item.href}
							>
								<a
									aria-label={item.linkText}
									href={item.href}
									dangerouslySetInnerHTML={{
										__html: item.linkText,
									}}
								/>
							</li>
						)
				)}
			</ul>
		</li>
	);
}
