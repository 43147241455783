import {
	aboutUsLinks,
	careerLinks,
	classicCarDealersLinks,
	classicCarInsuranceLinks,
	classicCarMakesLinks,
	contactLinks,
	cookiePolicyLinks,
	faqLinks,
	feedbackLinks,
	imprintLinks,
	insuranceComparisonLinks,
	magazineSubscriptionLinks,
	mediaDataLinks,
	partnerLinks,
	pressLinks,
	privacyPolicyLinks,
	sellClassicCarLinks,
	shopLinks,
	termsAndConditionsLinks,
	vehicleStorageFacilitiesLinks,
	warrantyLinks,
	type I18nLinks,
} from './localizedLinks';

type MenuItem = {
	i18nKey: string;
	href: I18nLinks;
};
export const footerMenuItems = {
	classicTrader: [
		{
			// t('footer.menu.classicTrader.aboutUs', 'About us')
			i18nKey: 'footer.menu.classicTrader.aboutUs',
			href: aboutUsLinks,
		},
		{
			// t('footer.menu.classicTrader.jobs', 'Jobs')
			i18nKey: 'footer.menu.classicTrader.jobs',
			href: careerLinks,
		},
		{
			// t('footer.menu.classicTrader.press', 'Press')
			i18nKey: 'footer.menu.classicTrader.press',
			href: pressLinks,
		},
		{
			// t('footer.menu.classicTrader.partner', 'Partner')
			i18nKey: 'footer.menu.classicTrader.partner',
			href: partnerLinks,
		},
	],
	support: [
		{
			// t('footer.menu.support.contact', 'Contact')
			i18nKey: 'footer.menu.support.contact',
			href: contactLinks,
		},
		{
			// t('footer.menu.support.feedback', 'Feedback')
			i18nKey: 'footer.menu.support.feedback',
			href: feedbackLinks,
		},
		{
			// t('footer.menu.support.faq', 'FAQ')
			i18nKey: 'footer.menu.support.faq',
			href: faqLinks,
		},
		{
			// t('footer.menu.support.reportContent', 'Report Content')
			i18nKey: 'footer.menu.support.reportContent',
			href: contactLinks,
		},
	],
	legal: [
		{
			// t('footer.menu.legal.imprint', 'Imprint')
			i18nKey: 'footer.menu.legal.imprint',
			href: imprintLinks,
		},
		{
			// t('footer.menu.legal.terms', 'Terms')
			i18nKey: 'footer.menu.legal.terms',
			href: termsAndConditionsLinks,
		},
		{
			// t('footer.menu.legal.privacy', 'Privacy')
			i18nKey: 'footer.menu.legal.privacy',
			href: privacyPolicyLinks,
		},
		{
			// t('footer.menu.legal.cookie', 'Cookie declaration')
			i18nKey: 'footer.menu.legal.cookie',
			href: cookiePolicyLinks,
		},
	],
	service: [
		{
			// t('footer.menu.service.shop', 'Shop')
			i18nKey: 'footer.menu.service.shop',
			href: shopLinks,
		},
		{
			// t('footer.menu.service.magazineSubscription', 'Magazine subscription')
			i18nKey: 'footer.menu.service.magazineSubscription',
			href: magazineSubscriptionLinks,
		},
		{
			// t('footer.menu.service.advertisement', 'Advertisement')
			i18nKey: 'footer.menu.service.advertisement',
			href: mediaDataLinks,
		},
		{
			// t('footer.menu.service.repairInsurance', 'Repair insurance')
			i18nKey: 'footer.menu.service.repairInsurance',
			href: warrantyLinks,
		},
		{
			// t('footer.menu.service.classicCarInsurance', 'Classic car insurance')
			i18nKey: 'footer.menu.service.classicCarInsurance',
			href: classicCarInsuranceLinks,
		},
		{
			// t('footer.menu.service.insuranceComparison', 'Insurance comparison')
			i18nKey: 'footer.menu.service.insuranceComparison',
			href: insuranceComparisonLinks,
		},
		{
			// t('footer.menu.service.classicCarMakes', 'Classic car makes')
			i18nKey: 'footer.menu.service.classicCarMakes',
			href: classicCarMakesLinks,
		},
		{
			// t('footer.menu.service.sellClassicCar', 'Sell classic car')
			i18nKey: 'footer.menu.service.sellClassicCar',
			href: sellClassicCarLinks,
		},
		{
			// t('footer.menu.service.classicCarDealers', 'Classic car dealers')
			i18nKey: 'footer.menu.service.classicCarDealers',
			href: classicCarDealersLinks,
		},
		{
			// t('footer.menu.service.vehicleStorages', 'Vehicle storages')
			i18nKey: 'footer.menu.service.vehicleStorages',
			href: vehicleStorageFacilitiesLinks,
		},
	],
} satisfies Record<string, MenuItem[]>;
export type FooterMenuItems = typeof footerMenuItems;
